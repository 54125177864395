import React, { useEffect, useState } from "react";
import { MuiOtpInputStyled, MyIdWrapper } from "./Otp.s";
import { WrapperError } from "components/my-id/MyId.s";
import NewOrder from "components";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTranslation } from "react-i18next";
import { useBankOtpMutation } from "store/services/bank-otp";
import useWebSocket from "../../utils/useWebSocket"; // Import your custom hook
import ErrorIcon from "../../assets/icons/error-icon.svg";
import { errorMessages } from "components/my-id/error";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

type Props = {};

const OtpBank2 = ({}: Props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { application_id } = useParams();

  const { t } = useTranslation();
  const [otp, setOtp] = useState("");
  const [seconds, setSeconds] = useState(60);
  const [countdownActive, setCountdownActive] = useState(true);
  const [expectation, setExpectation] = useState(false);
  const [errorBankStatus, setErrorBankStatus] = useState<any>();
  const [errorBankStatusSocket, setErrorBankStatusSocket] = useState<any>();
  const [errorBankStatusText, setErrorBankStatusText] = useState<any>(null);

  const [otpRequestSuccess, setOtpRequestSuccess] = useState(false);
  const language = localStorage.getItem("i18nextLng") || "en";

  const [
    requestBankOtp,
    { isLoading: isBankOtpLoading, error: BankOtpError, data: BankOtpData },
  ] = useBankOtpMutation();

  const { socket, loadingSocket } = useWebSocket(application_id!);

  useEffect(() => {
    if (socket) {
      socket.on("update", (update: any) => {
        setErrorBankStatusSocket(update.state)
        if (update.state === "failed") {
          setErrorBankStatus(
            Object.keys(errorMessages).includes(update.reason)
          );
          setErrorBankStatusText(update.reason);
  
        } else if (
          update &&
          !Object.keys(errorMessages).includes(update.reason) &&
          update.otp_type == null &&
          update.state !== "failed" && update.state !== 'waiting'
        ) {
          navigate(`/finish/${application_id}`);
        } else {
          setErrorBankStatus(false);
          setErrorBankStatusText(null);
        }
      });

      return () => {
        socket.off("update");
      };
    }
  }, [socket, language]);

  const handleRequestOtp = async () => {
    if (otp.length === 6) {
      const body = {
        id: Number(application_id),
        otp: otp,
      };

      try {
        const response = await requestBankOtp({ requestData: body, type: "davr" });
        if (response) {
          setOtpRequestSuccess(true);
        }
      } catch (error) {
        console.error("Error requesting OTP:", error);
      }
    }
  };

  useEffect(() => {
    const timer: NodeJS.Timeout | null = countdownActive
      ? setInterval(() => {
          setSeconds((prevSeconds) => {
            if (prevSeconds <= 1) {
              clearInterval(timer as NodeJS.Timeout);
              setCountdownActive(false);
              return 60;
            }
            return prevSeconds - 1;
          });
        }, 1000)
      : null;

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [countdownActive]);

  const renderError = () => {
    const errorMessage = errorMessages?.[errorBankStatusText]?.[language];
    return (
      <WrapperError>
        <img
          src={ErrorIcon as unknown as string}
          width={200}
          height={200}
          alt="logo"
        />
        <h3>{errorMessage}</h3>
        <button
          onClick={() => {
            window.localStorage.removeItem("card_type");
            navigate("/");
          }}
        >
          {t("MyId.5")}
        </button>
      </WrapperError>
    );
  };

  const handleResetOTP = () => {
  };
  const handleChange = (newValue: string) => {
    // Remove any non-numeric characters
    const numericValue = newValue.replace(/\D/g, "");
    setOtp(numericValue);
  };
  
  if ( !errorBankStatus && otpRequestSuccess && errorBankStatusSocket !== 'failed' ) {
    return (
      <NewOrder>
        <WrapperError>
          <h2>{t("MyId.3")}</h2>
          <h5>{t("MyId.4")}</h5>
          <AccessTimeIcon />
        </WrapperError>
      </NewOrder>
    );
  }

  return (
    <NewOrder>
      {errorBankStatus || errorBankStatusSocket == 'failed' ? (
        renderError()
      ) : (
        <MyIdWrapper>
          <h2>{t("OtbBank1.0")}</h2>
          <h5>{t("OtbBank1.1")}</h5>
          <div className="input-container">
            <MuiOtpInputStyled
              value={otp}
              onChange={handleChange}
              TextFieldsProps={{
                inputProps: {
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                },
              }}
              length={6}
            />
            {!countdownActive && (
              <p onClick={handleResetOTP}>
                <RefreshIcon />
                {t("Otp.2")}
              </p>
            )}
            {countdownActive && (
              <p>
                {t("Otp.3")} {seconds} {t("Otp.4")}
              </p>
            )}
            {BankOtpError && <span>{t("Otp.1")}</span>}
            <button
              className="button-send"
              onClick={handleRequestOtp}
              disabled={otp.length !== 6}
            >
              {t("Otp.5")}
            </button>
          </div>
        </MyIdWrapper>
      )}
    </NewOrder>
  );
};

export default OtpBank2 
