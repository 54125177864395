import React, { useEffect, useRef, useState } from "react";
import { useRequestJobIdFormMutation } from "store/services/MyId";
import { WrapperError, WrapperIframe } from "./MyId.s";
import { useLocation, useNavigate } from "react-router-dom";
import NewOrder from "components";
import ErrorIcon from "../../assets/icons/error-icon.svg";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useIdentifyMutation } from "store/services/identify";
import { useTranslation } from "react-i18next";
import useWebSocket from "../../utils/useWebSocket"; // Import your custom hook
import { errorMessages } from "./error";
import { WrapperTop } from "components/card-information/index.s";

interface ClientData {
  passportNumber: string;
  birthDate: string;
}

interface AccessTokenResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
}

type MyErrorData = {
  data: {
    response: {
      detail: [
        {
          msg: string;
          type: string;
          loc: any;
        }
      ];
    };
  };
};
interface MyIdIframeProps {
  broker: boolean;
}
const MyIdIframe: React.FC<MyIdIframeProps> = ({ broker }) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [avatarImage, setAvatarImage] = useState("");
  const [errorBankStatus, setErrorBankStatuts] = useState<any>();
  const [errorBankStatusText, setErrorBankStatutsText] = useState<any>();
  const [statusTextIframe, setStatusTextIframe] = useState("");
  const [alignment, setAlignment] = useState<"green" | "red">("red");
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const queryParams = new URLSearchParams(location.search);
  const passportNumber = queryParams.get("passportNumber");
  const birthDate = queryParams.get("birthDate");
  const application_id = queryParams.get("application_id");
  const language = localStorage.getItem("i18nextLng") || "en";
  const [errorBankStatusSocket, setErrorBankStatusSocket] = useState<any>();

  const [
    requestJobIdForm,
    {
      isLoading: isJobIdFormLoading,
      error: jobIdFormError,
      data: jobIdFormData,
    },
  ] = useRequestJobIdFormMutation();

  const [
    requestIdentify,
    { isLoading: isIdentifyLoading, error: IdentifyError, data: IdentifyData },
  ] = useIdentifyMutation();

  const dataJobIdFormError = jobIdFormError as MyErrorData;

  const { socket, loadingSocket } = useWebSocket(application_id!);

  useEffect(() => {
    if (jobIdFormData?.iframe_code) {
      const body = {
        passport: passportNumber,
        birth_date: birthDate,
        applicationId: application_id,
        iframe_code: jobIdFormData?.iframe_code,
        image: avatarImage,
      };
      requestIdentify({ body });
    }
  }, [jobIdFormData?.iframe_code]);

  useEffect(() => {
    const handleMessage = async (e: MessageEvent) => {
      setStatusTextIframe(e.data.state_message);
      if (e.data.cmd === -1) {
        iframeRef.current?.contentWindow?.postMessage(
          {
            cmd: "open",
            config: {
              locale: "ru",
              allowOnMobile: true,
              threshold: 50,
              style: `.Ticker { display: none!important } `,
            },
          },
          "*"
        );
      } else if (e.data.cmd === 2 && e.data.photo_from_camera) {
        const requestData = {
          applicationId: application_id,
          pass_data: passportNumber?.length !== 14 ? passportNumber : null,
          pinfl: passportNumber?.length === 14 ? passportNumber : null,
          birth_date: birthDate,
          photo_from_camera: { front: e.data.photo_from_camera },
        };
        await requestJobIdForm({ requestData });
        setAvatarImage(e.data.photo_from_camera);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    if (passportNumber?.length !== 14 && passportNumber?.length !== 9) {
      navigate(`/identification/${application_id}`);
    }
  }, [passportNumber, birthDate, navigate]);

  useEffect(() => {
    if (broker === false && (IdentifyData || IdentifyData?.iframe_code)) {
      navigate(`/finish/${application_id}`);
    } else {
      if (socket) {
        socket.on("update", (update: any) => {
          setErrorBankStatusSocket(update.state);
          if (update.state === "failed") {
            setErrorBankStatuts(
              Object.keys(errorMessages).includes(update.reason)
            );
            setErrorBankStatutsText(update.reason)
          } else if (update.otp_type === "uzcard" && update.b_status == "current") {
            navigate(`/otp-bank1/${application_id}`);
          } else if (update && update.state == "success") {
            navigate(`/finish/${application_id}`);
          }
        });
      }
    }
  }, [socket, IdentifyData?.iframe_code, IdentifyData]);
  console.log(errorBankStatusSocket);
  
  if (
    !errorBankStatus &&
    IdentifyData?.iframe_code &&
    errorBankStatusSocket === "waiting"
  ) {
    return (
      <NewOrder>
        <WrapperError>
          <AccessTimeIcon />
          <h2>{t("Заявка на рассмотрении")}</h2>
          <h5 style={{ marginBottom: "32px" }}>
            {t("Вы успешно зарегистрировались")}
          </h5>
          <h5 style={{ marginBottom: "32px" }}>
            {t(
              "Через пару минут мы сообщим о вашем лимите рассрочки Спасибо за ожидание"
            )}
          </h5>
        </WrapperError>
      </NewOrder>
    );
  }

  const renderError = () => {
    if (dataJobIdFormError?.data?.response.detail) {
      const hasBirthDateError = dataJobIdFormError.data.response.detail.some(
        (elem) => elem.loc[1] === "birth_date"
      );
      const hasPinflError = dataJobIdFormError.data.response.detail.some(
        (elem) => elem.loc[1] === "pinfl"
      );
      return (
        <WrapperError>
          <img
            src={ErrorIcon as unknown as string}
            width={200}
            height={200}
            alt="logo"
          />
          <h3>
            {hasBirthDateError && hasPinflError
              ? t("MyId.0")
              : hasBirthDateError
              ? t("MyId.1")
              : hasPinflError
              ? t("MyId.2")
              : t("MyId.defaultError")}
          </h3>
          <button
            onClick={() => {
              window.localStorage.removeItem("card_type");
              navigate("/");
            }}
          >
            {t("MyId.5")}
          </button>
        </WrapperError>
      );
    }

    // Fallback for unknown errors
    const errorMessage = errorMessages?.[errorBankStatusText]?.[language];
    return (
      <WrapperError>
        <img
          src={ErrorIcon as unknown as string}
          width={200}
          height={200}
          alt="logo"
        />
        <h3>{errorMessage}</h3>
        <button
          onClick={() => {
            window.localStorage.removeItem("card_type");
            navigate("/");
          }}
        >
          {t("MyId.5")}
        </button>
      </WrapperError>
    );
  };

  return (
    <NewOrder>
      {dataJobIdFormError?.data?.response.detail ||
      dataJobIdFormError ||
      errorBankStatus ||
      errorBankStatusSocket === "failed" ? (
        renderError()
      ) : (
        <>
          <WrapperTop>
            <h3>{t("Идентификация")}</h3>
            <h5>
              {t(
                "Убедитесь, что ваше лицо находится в специально отведённом месте"
              )}
            </h5>
          </WrapperTop>
          <div style={{ position: "relative", height: "400px" }}>
            <iframe
              ref={iframeRef}
              id="myid_iframe"
              src="https://docs.myid.uz/iframe"
              allow="camera;fullscreen"
              width="100%"
              height="400px"
              style={{ borderRadius: "12px", marginTop: "32px" }}
              frameBorder="0"
              onLoad={() => {
                iframeRef.current?.contentWindow?.postMessage(
                  { cmd: "requestCameraPermissions", allowOnMobile: true },
                  "*"
                );
              }}
            ></iframe>
            <div
              style={{
                position: "absolute",
                top: "55%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "250px",
                height: "300px",
                borderRadius: "50%",
                border: `5px dashed ${
                  statusTextIframe == "Подвиньтесь ближе к камере." ||
                  statusTextIframe == "Пожалуйста, смотрите прямо в камеру."
                    ? "red"
                    : "green"
                }`,
                boxSizing: "border-box",
                pointerEvents: "none",
              }}
            ></div>
          </div>

          <h4
            style={{
              backgroundColor:
                statusTextIframe == "Подвиньтесь ближе к камере." ||
                statusTextIframe == "Пожалуйста, смотрите прямо в камеру."
                  ? "#FCECEA"
                  : "#E3FAD6",
              padding: "24px 12px",
              textAlign: "center",
              borderRadius: "12px",
              marginTop: "50px",
              fontFamily: "Arial",
            }}
          >
            {statusTextIframe}
          </h4>
        </>
      )}
    </NewOrder>
  );
};

export default MyIdIframe;
